@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Noto Sans", sans-serif;
  background-color: #fdfdfd;
  position: relative;
  max-width: 1720px;
  margin: 0 auto;
  overflow-x: hidden;
}
.custom-hero-section {
  display: none;
}

.animation {
  transition: 0.5s ease-in-out all;
}

  /* LCPページの下マージン設定 */
.lcp-container {
  max-height: 9350px;
}

@media (max-width: 500px) {
  .custom-hero-section {
    display: block;
  }

  .text-image {
    width: 350px;
    padding: 0 1em;
  }

  .header {
    left: 0;
    right: 0;
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
  }

  .logo-div {
    display: none;
  }

  .w-\[500px\] {
    scale: 0.8;
    transform: translateY(-95px);
  }
  .w-\[22rem\] {
    width: 17.8rem;
  }

  .mt-\[4\.1rem\] {
    margin-top: 5.1rem;
  }

  .hero-img {
    display: none;
  }

  .second-section {
    top: -12.5em;
  }
  .third-section {
    top: -30.2em;
  }
  .fourth-section {
    top: -64em;
  }
  .fifth-section {
    top: -79.3em;
  }
  .sixth-section {
    top: -97.3em;
  }
  .seventh-section {
    top: -139.5em;
  }
  .eighth-section {
    top: -155.9em;
  }

  /* .fixed-section {
    background: transparent;
    padding: 0;
    position: relative;
  } */

    /* LCPページの下マージン設定 */
  .lcp-container {
    max-height: 7300px;
  }
}

@media (max-width: 425px) {
  .w-\[500px\] {
    scale: 0.65;
    transform: translateY(-200px);
  }

  .second-section {
    top: -20em;
  }
  .third-section {
    top: -49.5em;
  }
  .fourth-section {
    top: -87em;
  }
  .fifth-section {
    top: -118.8em;
  }
  .sixth-section {
    top: -150.2em;
  }
  .seventh-section {
    top: -206em;
  }
  .eighth-section {
    top: -231.8em;
  }

  /* LCPページの下マージン設定 */
  .lcp-container {
    max-height: 6050px;
  }
}

.text-very-small {
  font-size: 0.6rem; /* または必要なサイズに設定 */
}

@font-face {
  font-family: "ShipporiMinchoB1-Medium";
  src: url("../public/fonts/ShipporiMinchoB1-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ArmedLemon";
  src: url("../public/fonts/ArmedLemon.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "karakaze-R";
  src: url("../public/fonts/karakaze-R.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.shippori-font {
  font-family: "ShipporiMinchoB1-Bold", serif;
}

.lemon-font {
  font-family: "ArmedLemon", serif;
}

.karakaze-font {
  font-family: "karakaze-R", serif;
}

.triangle-container::after {
  content: "";
  position: absolute;
  top: 0;
  right: -20px;
  border-width: 21px 0 20px 20px; /* Adjust the triangle size */
  border-style: solid;
  border-color: transparent transparent transparent #f77d1f; /* Set the triangle color */
}
